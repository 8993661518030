"use client";

import React, { useState } from "react";
import styles from "./ChallengeGrid.module.scss";
import clsx from "clsx";
import { ChallengeCard } from "@/components/components/ChallengeCard";
import { set } from "date-fns";
import { Button } from "@/components/actions/Button";
import { useChallenges } from "@/providers/Challenges";
import * as Toast from "@radix-ui/react-toast";
import If from "@/components/common/If";

/**
 *
 * ChallengeGrid
 *
 */
const ChallengeGrid = ({ fullPage = false, isLight = false, ...props }) => {
  const { challenges, removeChallenge, loadingChallenges, thinknados } =
    useChallenges();
  const [isDeleted, setIsDeleted] = useState(false);
  // const [challengeList, setChallengeList] = useState(challenges);
  const onDelete = async (id = "") => {
    removeChallenge(id);
    setIsDeleted(true);
    // setChallengeList(challengeList.filter((c) => c.id !== id));
  };

  return (
    <>
      <section
        className={clsx(
          styles["challenge-grid"],
          ` grid ${fullPage ? "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-gutter" : "container grid-cols-1"} gap-4`
        )}
        {...props}
      >
        {loadingChallenges && (
          <div className="flex flex-col gap-4 items-center justify-center">
            <div
              className={`text-xl text-center ${fullPage ? "text-black" : "text-white"}`}
            >
              Loading...
            </div>
          </div>
        )}

        {challenges?.length === 0 && !fullPage && !loadingChallenges && (
          <div className="flex flex-col  gap-4">
            <p className="text-xl text-white">No challenges yet</p>
          </div>
        )}

        {challenges?.length === 0 && fullPage && !loadingChallenges && (
          <div className="flex flex-col gap-4 col-span-2">
            <p className="text-xl text-black text-center">
              Welcome to Thinknado, where your ideas will shine like diamonds
              and your imagination will dance like a wizard's spell! You’ve just
              stepped into a world where boring is banned, and creativity reigns
              supreme. Get ready to flip conventional thinking on its head,
              embrace chaos, and laugh in the face of the ordinary. Dive in,
              dare to be different, and let's turn your wildest dreams into
              reality. Remember, if your ideas are still boring, you owe us
              money—or a small goat. Welcome to the revolution!
            </p>
            <Button href="/app" className="w-1/2 mx-auto">
              Start a new challenge
            </Button>
          </div>
        )}

        <If condition={thinknados?.length > 0}>
          {thinknados.map((thinknado) => (
            <ChallengeCard
              onDelete={onDelete}
              key={thinknado.id}
              challenge={thinknado}
              isLight={isLight}
              isThinknado
            />
          ))}
        </If>

        {challenges?.length > 0 &&
          challenges.map((challenge) => (
            <ChallengeCard
              onDelete={onDelete}
              key={challenge.id}
              challenge={challenge}
              isLight={isLight}
            />
          ))}
      </section>
      <Toast.Root
        className="bg-black text-white rounded-md shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] p-[15px] grid [grid-template-areas:_'title_action'_'description_action'] grid-cols-[auto_max-content] gap-x-[15px] items-center data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut"
        open={isDeleted}
        onOpenChange={setIsDeleted}
      >
        <Toast.Title className="[grid-area:_title] mb-[5px] font-bold text-slate12 text-[15px] uppercase text-white">
          Success
        </Toast.Title>
        <Toast.Description asChild>
          <p className="text-gray text-sm">Your session has been deleted.</p>
        </Toast.Description>
        <Toast.Action className="[grid-area:_action]" asChild altText="Close">
          <button className="inline-flex items-center justify-center rounded font-medium text-xs px-[10px] leading-none h-[25px] bg-pink text-white ">
            Close
          </button>
        </Toast.Action>
      </Toast.Root>
    </>
  );
};

export default ChallengeGrid;
